.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 20px;
  padding: 20px;
  width: 88%;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  height: 100%;
  max-height: 900px; /* Set a max-height to control the height */
  overflow: hidden; /* Hide overflow content */
}

.mentor-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto;
}

.box-content {
  padding: 20px 20px 0;
  text-align: center;
  flex-grow: 1;
  margin-top: 30px;
  overflow: auto; /* Allow scrolling if content overflows */
}

.mentor-details {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.mentor-logo {
  min-width: 150px;
  width: 100%;
  height: 60px;
  object-fit: fill;

}

.box-footer {
  text-align: center;
  padding: 10px 0;
  flex-shrink: 0;
}

.mentor-button {
  background-color: #ff7f00;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.mentor-button:hover {
  background-color: #e06d00;
}

@media screen and (max-width: 420px) {
  .wrapper {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 0px;
  }

  .mentor-logo {
    min-width: 100px;
  }
}
